import styled from "styled-components"
import Img from "gatsby-image"

export const SearchWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: opacity 0.4s;

  .ais-InstantSearch__root {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .ais-SearchBox,
  .ais-Stats {
    background: var(--black_background_contrast);
    padding: 0.5rem 3rem;
  }

  .ais-SearchBox {
    padding-top: 6rem;
  }

  .ais-Stats {
    color: var(--white);
    margin-bottom: 2rem;
  }

  .ais-SearchBox-input {
    background: none;
    border: none;
    border-bottom: 5px solid var(--black_background);
    color: var(--white);
    display: flex;
    font-size: 1.6rem;
    padding: 0.5rem;
    width: 100%;
    &::placeholder {
      color: var(--white);
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`
export const SearchAlgolia = styled(Img)`
  width: 168px;
  height: 24px;
  align-self: flex-end;
  margin: 1rem;
`
