import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Search from "../components/Search"

const SearchPage = () => (
  <Layout visibleSideBar={true}>
    <SEO
      title="Pesquisa"
      descripition="Se quer pesquisar algum conteúdo sobre empreendedorismo, afiliados, marketing digital aqui é o lugar, basta digitar sua pesquisa e nossa busca irá trazer todos os nosso posts sobre o assunto."
    />
    <Search />
  </Layout>
)

export default SearchPage
